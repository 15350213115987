<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <c-card title="LBLREV" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange" v-if="popupParam.opmFolderDetailId">
          <!-- 개정 -->
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="row">
                <div class="col-1">
                  <!-- 개정번호 -->
                  <c-text
                    :required="editable && isRevision"
                    :editable="editable && isRevision"
                    label="LBLREVNO"
                    name="revisionNum"
                    v-model="folderDetail.revisionNum">
                  </c-text>
                </div>
                <div class="col-2">
                  <!-- 개정일시 -->
                  <c-text
                    :editable="false"
                    label="LBLREVPERIOD"
                    name="regDtStr"
                    v-model="folderDetail.regDtStr">
                  </c-text>
                </div>
                <div class="col-1">
                  <!-- 개정자 -->
                  <c-text
                    :editable="false"
                    label="LBLREVUSER"
                    name="regUserName"
                    v-model="folderDetail.regUserName">
                  </c-text>
                </div>
                <div class="col-4">
                  <!-- 개정사유 -->
                  <c-text
                    :required="editable && isRevision"
                    :editable="editable && isRevision"
                    label="LBLREVREASON"
                    name="revisionContent"
                    v-model="folderDetail.revisionContent">
                  </c-text>
                </div>
              </div>
            </div>
          </template>
        </c-card>
        <!-- 공정안전보고서 기본정보 -->
        <c-card title="규정 및 안전운전지침서 기본정보" class="cardClassDetailForm">
          <template slot="card-select">
            <!-- 개정이력 -->
            <c-select
              :editable="!isRevision"
              v-if="popupParam.opmFolderDetailId"
              :comboItems="gridrev.data"
              type="custom"
              typetext="LBL0010565"
              itemText="revisionNum"
              itemValue="opmFolderDetailId"
              name="selectedOpmFolderDetailId"
              label=""
              v-model="selectedOpmFolderDetailId"
              @input="rowClickRev"
            ></c-select>
          </template>
          <template slot="card-button">
            <q-btn-group outline >
              <!-- 개정 -->
              <c-btn
                v-show="(editable && popupParam.opmFolderDetailId && !isRevision) && isEnalbed"
                label="LBLREV"
                icon="restart_alt"
                @btnClicked="setRevision" />
              <!-- 개정취소 -->
              <c-btn
                v-show="(editable && popupParam.opmFolderDetailId && isRevision) && isEnalbed"
                label="LBLREVCANCEL"
                icon="restart_alt"
                @btnClicked="cancelRevision" />
              <!-- 삭제 -->
              <c-btn
                v-if="editable && popupParam.opmFolderDetailId && isEnalbed"
                label="LBLREMOVE"
                icon="remove"
                @btnClicked="deleteData" />
              <!-- 저장 -->
              <c-btn
                v-if="editable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="folderDetail"
                :mappingType="saveType"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveFolderDetail"
                @btnCallback="saveCallback" 
              />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-6">
              <!-- 절차서제목 -->
              <c-text
                required
                :disabled="isRevision"
                :editable="editable"
                label="제목"
                name="folderDetailName"
                v-model="folderDetail.folderDetailName"
              />
            </div>
            <div class="col-3">
              <!-- 기준일 -->
              <c-datepicker
                label="LBL0010597"
                :disabled="isRevision"
                :editable="editable"
                name="standardDate"
                v-model="folderDetail.standardDate"
              />
            </div>
            <div class="col-3">
              <c-plant
                :required="true"
                :disabled="isRevision"
                :editable="editable"
                type="edit"
                name="plantCd"
                v-model="folderDetail.plantCd" />
            </div>
          </template>
        </c-card>
        <div style="padding-top: 20px !important;">
          <!-- 절차서 파일 -->
          <c-upload
            :attachInfo="attachInfo"
            :editable="editable"
            label="규정 및 안전운전지침서 파일">
          </c-upload>
        </div>
      </div>
    </div>
  </q-form>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'process-safety-report-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          opmFolderId: '',
          opmFolderDetailId: '',
        }
      },
    },
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'OPM_SAFETY_REPORT',
        taskKey: '',
        beforeTaskKey: '',
        isRev: false,
      },
      editable: true,
      folderDetail: {
        opmFolderDetailId: '',
        opmFolderId: '',
        folderDetailName: '',
        groupId: '',
        revNo: 1,
        revisionNum: '',
        revisionContent: '제정',
        plantCd: '',
        standardDate: '',
        sortOrder: '',
        deleteFlag: '',
        useFlag: '',
        regUserId: '',
        chgUserId: '',
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            // 개정번호
            label: 'LBLREVNO',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            // 개정사유
            label: 'LBLREVREASON',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            // 개정일시
            label: 'LBLREVPERIOD',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            // 개정자
            label: 'LBLREVUSER',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
      disabled: false,
      saveUrl: 'transactionConfig.sop.opm.folderDetail.insert.url',
      saveType: 'POST',
      isSave: false,
      getUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      getRevUrl: '',
      isRevision: false,
      isEnalbed: true,
      realKey: '',
      selectedOpmFolderDetailId: '',
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      this.getUrl = selectConfig.sop.opm.folderDetail.get.url;
      this.insertUrl = transactionConfig.sop.opm.folderDetail.insert.url;
      this.updateUrl = transactionConfig.sop.opm.folderDetail.update.url;
      this.deleteUrl = transactionConfig.sop.opm.folderDetail.delete.url;
      this.revlistUrl = selectConfig.sop.opm.folderDetail.rev.url;
      this.getRevUrl = selectConfig.sop.opm.folderDetail.rev.get.url;

      if (this.popupParam.opmFolderId) this.folderDetail.opmFolderId = this.popupParam.opmFolderId
      this.getData();
    },
    getData() {
      this.isRevision = false;
      if (this.popupParam.opmFolderDetailId) {
        this.$set(this.attachInfo, 'taskKey', this.popupParam.opmFolderDetailId)
        this.getDetail(this.popupParam.opmFolderDetailId, true);
        this.selectedOpmFolderDetailId = this.popupParam.opmFolderDetailId
      }
    },
    getDetail(_opmFolderDetailId, _check) {
      this.$http.url = this.$format(this.getUrl, _opmFolderDetailId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.folderDetail = _result.data;
        this.isRevision = false;
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        if(_result.data.opmFolderDetailId == this.popupParam.opmFolderDetailId && this.isEnalbed) {
          this.editable = this.$route.meta.editable;
        } else {
          this.editable = false;
        }
      },);
    }, 
    saveFolderDetail() {
      if (this.popupParam.opmFolderDetailId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      let saveMsg = 'MSGSAVE';  // 저장하시겠습니까?
      if (this.isRevision) {
        saveMsg = 'MSGREVSAVE';
        // 현재버전을 개정하여 저장하시겠습니까?
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.folderDetail.regUserId = this.$store.getters.user.userId
              this.folderDetail.chgUserId = this.$store.getters.user.userId
              
              if (this.isRevision) {
                this.saveUrl = this.insertUrl;
                this.saveType = 'POST';
                this.folderDetail.revNo = parseInt(this.folderDetail.revNo) + 1;
              }
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    }, 
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.opmFolderDetailId = _result.data
      this.$set(this.attachInfo, 'taskKey', _result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.saveCallData = uid();
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    setRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.folderDetail.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.folderDetail.revisionNum = _result.data;
        this.folderDetail.revisionContent = '';
        this.folderDetail.regDtStr = '';
        this.folderDetail.regUserName = '';
        this.isRevision = true;
        /**
         * 첨부파일 셋팅
         */
        this.$set(this.attachInfo, 'taskKey', '')
        this.$set(this.attachInfo, 'beforeTaskKey', this.popupParam.opmFolderDetailId)
        this.$set(this.attachInfo, 'isRev', true)
      });
    },
    cancelRevision() {
      this.isRevision = false;
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'taskKey', this.popupParam.opmFolderDetailId)
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.getData();
    },
    rowClickRev(row) {
      if (row) {
        if (row != this.folderDetail.opmFolderDetailId) {
          this.selectedOpmFolderDetailId = row;
          this.getDetail(this.selectedOpmFolderDetailId, false);
          this.$set(this.attachInfo, 'taskKey', this.selectedOpmFolderDetailId)
        }
      }
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.opmFolderDetailId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  